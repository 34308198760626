<script setup lang="ts">
const description =
  'Home Boligoverblik giver dig et nemt overblik over, hvordan du kan forbedre boligen, så du får de bedste rammer at bo og leve i.'

useMetaTags(description)
</script>
<template>
  <main class="grid-outer relative min-h-screen items-center">
    <slot />
    <KeyholeBackgroundIcon />
  </main>
</template>
