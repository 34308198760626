<template>
  <svg
    viewBox="0 0 720 1638"
    fill="none"
    class="absolute bottom-0 right-0 -z-10 hidden h-[50vh] w-auto md:block"
  >
    <path
      d="M773.848 913.686L1067.3 1644.5H3.69786L297.152 913.686L298.019 911.527L295.928 910.508C147.156 837.96 47.7302 692.893 47.7302 492.865C47.7302 347.514 98.6025 224.955 184.92 138.738C271.239 52.52 393.152 2.5 535.5 2.5C677.849 2.5 799.761 52.52 886.08 138.738C972.398 224.955 1023.27 347.514 1023.27 492.865C1023.27 692.893 923.845 837.96 775.072 910.508L772.981 911.527L773.848 913.686Z"
      stroke="#E51F2B"
      stroke-width="5"
    />
  </svg>
</template>
